.board div {
  box-sizing: border-box;
  text-transform: uppercase;
}


@media only screen and (max-width: 480px) {
  .responsive {
    background: #cde6d0;
    width: 90vw;
    height: 90vw;
    margin: 10px auto;
  }

}

/* Tablet */
@media only screen and (min-width:480px) and (max-width:768px) {
  .responsive {
    background: #cde6d0;
    width: 60vw;
    height: 60vw;
    margin: 10px auto;
  }

}


/* Desktop */
@media only screen and (min-width: 769px) {
  .responsive {
    background: #cde6d0;
    width: 70vh;
    height: 70vh;
    margin: 10px auto;
  }

}



/* @media only screen and (min-width: 600px) {
  .responsive {
    width: 40vw;
    height: 40vw;
  }

  .firstLine {
    font-size: 0.5vw;
    overflow-wrap: break-word;
    padding-left: 2px;
    padding-right: 2px;
  }
} */

/* @media only screen and (min-width: 992px) {
  .responsive {
    width: 20vw;
    height: 20vw;
  }

  .firstLine {
    font-size: 0.5vw;
    overflow-wrap: break-word;
    padding-left: 2px;
    padding-right: 2px;
  }
} */


.board {
  transform: rotate(45deg);
}

.mainSquare {
  height: 100%;
  /* outline: 1px dashed #c2dec5; */
  outline-offset: -13px;
}

.row {
  width: 100%;
  display: flex;
}

.top {
  height: 15.384615385%;
}

.center {
  height: 69.23076923%;
}

.square1 {
  outline: 1px solid black;
  flex-grow: 1;
  position: relative;
}

.square2 {
  flex-grow: 2;
  outline: 1px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
}

.square9 {
  flex-grow: 9;
  outline: 1px solid black;
  position: relative;
  /* transform: rotate(45deg); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.squareSide {
  outline: 1px solid black;
  flex-grow: 1;
  position: relative;
  word-wrap: break-word;
}

.header {
  height: 21%;
  position: absolute;
  outline: 2px solid black;
  background: grey;
}

.headerSide {
  /* height: 100%; */
  width: 21%;
  position: absolute;
  outline: 2px solid black;
}

.header-top {
  bottom: 1px;
  left: 1px;
  right: 1px;
}

.header-bottom {
  top: 1px;
  left: 1px;
  right: 1px;
}

.header-left {
  top: 1px;
  bottom: 1px;
  right: 1px;
  background: grey;
}

.header-right {
  top: 1px;
  bottom: 1px;
  left: 1px;
  background: grey;
}

.firstLine {
  position: absolute;
  font-size: 1.0vw;
  font-weight: 500;
  /* line-height: 1vw; */
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  z-index: 2;
}

@media only screen and (max-width: 480px) {
  .firstLine {
    font-size: 0.8vw;
  }

}

/* Tablet */
@media only screen and (min-width:480px) and (max-width:768px) {
  .firstLine {
    font-size: 0.6vw;
    overflow-wrap: break-word;
    padding-left: 2px;
    padding-right: 2px;
  }

}


/* Desktop */
@media only screen and (min-width: 769px) {
  .firstLine {
    font-size: 0.4vw;
    padding-left: 2px;
    padding-right: 2px;
  }

}

.firstLine-top {
  left: 1px;
  right: 1px;
  bottom: 30%;
  height: 10%;
}

.firstLine-top.no-color {
  bottom: 12%;
}

.firstLine-left {
  top: 0px;
  bottom: 0px;
  right: 30%;
  width: 50%;
}

.firstLine-left.no-color {
  right: 12%;
}

.firstLine-right {
  /* position: inherit; */
  top: 0;
  bottom: 0;
  width: 50%;
  left: 30%;
}

.firstLine-right-new {
  font-size: 0.8vw;
  font-weight: 500;
  line-height: 1vw;
  text-align: center;

}

.firstLine-right.no-color {
  left: 12%;
}

.firstLine-bottom {
  left: 0;
  right: 0;
  top: 30%;
}

.firstLine-bottom.no-color {
  top: 12%;
}

.red {
  background-color: #ed1b24;
}

.yellow {
  background-color: #fef200;
}

.lightblue {
  background-color: #aae0fa;
}

.brown {
  background-color: #955436;
}

.blue {
  background-color: #0072bb;
}

.green {
  background-color: #1fb25a;
}

.orange {
  background-color: #f7941d;
}

.purple {
  background-color: #d93a96;
}

.rotation1 {
  transform: rotate(90deg);
}

.rotation2 {
  transform: rotate(180deg);
}

.rotation3 {
  transform: rotate(-90deg);
}

.rotation4 {
  transform: rotate(135deg);
}

.logoBox {
  width: 46%;
  background: #ed1b24;
  position: absolute;
  transform: rotateZ(-45deg) translateX(-27%) translateY(237%);
  border: 3px solid black;
  text-align: center;
  box-shadow: inset 0px 2px 12px 0px white;
}

.logoName {
  font-size: 7.1vw;
  color: white;
  font-family: futura;
  text-shadow: -4px 3px 0px black;
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #cfc2c3;
}

.card-box {
  position: absolute;
  width: 15%;
  height: 9vw;
}

.card-blue {
  background: linear-gradient(#4ccaf4, #a3dff9);
  transform: rotateZ(-45deg) translateX(-13%) translateY(66%);
}

.card-blue-inside {
  background: linear-gradient(#a3dff9, #4ccaf4);
  width: 94%;
  height: 94%;
  margin: 0.3vw auto;
  position: relative;
}

.card-orange {
  background: linear-gradient(#f99120, #f57420);
  transform: rotateZ(-45deg) translateX(-13%) translateY(610%);
}

.card-orange-inside {
  background: linear-gradient(#f57420, #f99120);
  width: 94%;
  height: 94%;
  margin: 0.3vw auto;
  position: relative;
}

.corner {
  position: absolute;
  text-align: center;
  line-height: 8vw;
  font-size: 1.2vw;
  font-weight: 500;
}

.corner1 {
  /* transform: rotateZ(135deg) translateX(-66%) translateY(-15%); */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corner2 {
  /* transform: rotateZ(-135deg) translateX(-30%) translateY(23%); */
  transform: rotate(225deg);
  line-height: 9vw;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corner3 {
  /* transform: rotateZ(-45deg) translateX(-14%) translateY(26%); */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  line-height: 1.4vw;
  font-size: 1vw;
}

.corner4 {
  /* transform: rotateZ(45deg) translateX(-9%) translateY(-31%); */
  transform: rotate(90deg);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 7vw;
}

.squareCorner {
  height: 65%;
  width: 65%;
  border: 2px solid black;
  right: -1px;
  top: -1px;
  position: absolute;
}

.corner4-left {
  position: absolute;
  transform: rotateZ(90deg) translateX(80%) translateY(-36%);
  font-size: 1.2vw;
  font-weight: 500;
}

.corner4-bottom {
  position: absolute;
  transform: translateX(110%) translateY(620%);
  font-size: 1.2vw;
  font-weight: 500;
}

.form-container {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  padding-top: 100px;
  /* background-color: green; */
  flex-direction: column;
  align-items: center;
}

form {
  width: 80%;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.container {
  width: 80%;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.form-error {
  width: 100%;
  background-color: #EF9A9A;
  margin-top: 30px;
  border-radius: 16px;


  p {
    padding: 0;
    margin: 0.5em;
  }
}

label {
  text-align: start;
  text-transform: none;
  margin-top: 5px;
}

.input-prefix-box {
  display: flex;
}

input[type=text],
input[type=number],
input[type=password],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  background-color: #ffffff;
  color: black
}

.input-prefix-box input {
  border-radius: 0 16px 16px 0;
}

.prefix {
  padding: 12px 20px;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  border-radius: 16px 0px 0px 16px;
}

select {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  /* linear-gradient(to right, #ccc, #ccc); */
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  /* calc(100% - 2.5em) 0.5em; */
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

input[type=checkbox] {
  height: 25px;
  width: 25px;
  border: none;
  /* -webkit-appearance: none; */
}

input:invalid {
  border: 1px solid red;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}

button {
  padding: 10px 20px;
  border-radius: 16px;
  font-size: 1em;
  background-color: transparent;
  font-weight: 800;
  border: none;
  color: #6B7AC9;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

button[type=submit] {
  padding: 10px 20px;
  border-radius: 16px;
  font-size: 1em;
  background-color: #6B7AC9;
  font-weight: 800;
  border: none;
  color: #ffffff
}

.temp {
  background-color: blue;
}

.log-item {
  background-color: #e6ecff;
  width: 80%;
  margin: 10px;
  border-radius: 20px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 5px;
  display: flex;
}

.log-item p {
  margin: 5px;
  width: 70%;
  max-width: 70%;

}

.log-item:last-of-type {
  background-color: transparent;
  box-shadow: none;
}

.log-item p.date {
  text-align: end;
  font-weight: 600;
  width: 30%;
  max-width: 30%;
  padding-right: 5px;
  border-right: 1px solid grey;
}


/* input, textarea, select {
    border:0; outline:0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    box-shadow: (0 1px 1px rgba(black, 0.1));
    resize: none;
    -webkit-appearance: none;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    background-position-x: calc( 100% - 5px );

   &:focus {
      box-shadow: 0 0px 2px rgba(red, 1) !important;
   }
 }

 #input-submit {
    color: white;
    background: red;
    cursor: pointer;

   &:hover {
      box-shadow: (0 1px 1px 1px rgba(#aaa, 0.6));
   }
 } */