.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-container {
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: #D5E0FF;
  display: flex;
  /* height: 100%; */
  width: 100%;
}

.home-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
}

.board {
  transform: rotate(45deg);
}

.board-container {
  position: absolute;
  top: 0;
  width: 90vw;
  max-width: 90vw;
  height: 90vw;
  max-height: 90vw;
  /* max-height: calc(75vw); */
  /* background-color: blue; */
  z-index: 99;
  /* display: flex; */
  /* flex-direction: column; */
  border: 2px solid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
}

.board-background {
  z-index: 1;
  width: 90vw;
  height: 90vw;
  background-color: #D0E8D2;
  border: 2px solid;
  position: relative;
  /* transform: rotate(45deg); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.monopoly-logo {
  background-color: #EE1F28;
  transform: rotate(-45deg);
  border: 2px solid white;
  box-shadow: 2px 2px 2px black;
  position: absolute;
  min-width: 50%;
  min-height: 35px;
}

.monopoly-logo-text {
  margin: 0;
  padding: 2px;
  color: white;
  text-shadow: 0.1px 0.1px 0.1px black;
  font-size: 2em;
}

.monopoly-logo-image {
  position: absolute;
  width: 100px;
  transform: rotate(-45deg);
  padding-bottom: 60px;
}



h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: #6B7AC9;
}

.top-row {
  width: 100%;
  /* height: 10vw; */
  border: 1px solid black;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  transform: rotate(180deg);
}

.street-content {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* height: 100%; */
  background-color: red;
  /* transform-origin: top left; */
  /* Note: for a CLOCKWISE rotation, use the commented-out
     transform instead of this one. */
  /* transform: rotate(90deg) ; */
  display: flex;
}

.corner-top-left {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  border: 1px solid black;

}

.corner-top-right {
  grid-column: 11 / 13;
  grid-row: 1 / 3;
  border: 1px solid black;
}

.corner-bottom-left {
  grid-column: 1 / 3;
  grid-row: 11 / 13;
  border: 1px solid black;
}

.corner-bottom-right {
  grid-column: 11 / 13;
  grid-row: 11 / 13;
  border: 1px solid black;
}

.center {
  /* background-color: pink; */
  grid-column: 3 / 11;
  grid-row: 3 / 11;
  border: 1px solid black;
}

.street-header-bottom {
  width: calc(100% - 1px);
  min-height: calc(20% - 1px);
  ;
  height: calc(20% - 1px);
  border: 1px solid black;
  position: fixed
    /* background-color: green; */
}

.street-header-top {
  width: 100%;
  height: 20%;
  min-height: 20%;
  position: fixed;
  /* background-color: green; */
}

.second-row {
  width: calc(100% - 1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 1 / 3;
}

.third-row {
  width: calc(100% -1px);
  max-width: 100%;
  overflow: hidden;
  /* height: 10vw; */
  border: 1px solid black;
  grid-row: 11 / 13;

}

.right-row-1 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 3/4;
  display: flex;
}

.right-row-2 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 4/5;
  display: flex
}

.right-row-3 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 5/6;
  display: flex
}

.right-row-4 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 6/7;
  display: flex
}

.right-row-5 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 7/8;
  display: flex
}

.right-row-6 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 8/9;
  display: flex
}

.right-row-7 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 9/10;
  display: flex
}

.right-row-8 {
  width: calc(100% -1px);
  /* height: 10vw; */
  border: 1px solid black;
  grid-column: 11 / 13;
  grid-row: 10/11;
  display: flex
}

.align-flex-end {
  align-items: flex-end;
}

.align-flex-start {
  display: flex;
  align-items: start;
}

.justify-flex-end {
  display: flex;
  justify-content: flex-end;
}


.h-100p {
  height: 100%;
}

.w-20p {
  width: 20%;
}

.navbar-container {
  z-index: 9;
}

.navbar {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100vw;
  /* min-height: 60px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #6B7AC9;
  /* padding: 1em; */
  display: flex;
  justify-content: space-around;
  align-items: center;

}


.navbar-item {
  height: 100%;
  width: 100%;
  /* background-color: green; */
  padding: 18px;
}

.navbar-text {
  display: none;
}

.navbar-close {
  display: none;
}

.navbar-menu-icon {
  display: none;
  visibility: hidden;
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 1280px) {
  .navbar {
    bottom: unset;
    width: 250px;
    left: 0px;
    height: 100%;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    visibility: visible;
    transition: left 0.3s ease-in-out;
  }

  .navbar-menu-icon {
    display: block;
    position: absolute;
    left: 20px;
    top: 10px;
    cursor: pointer;
    visibility: visible;
  }

  .navbar.closed {
    left: -250px;
  }

  .navbar-item {
    height: unset;
    width: 100%;
    /* background-color: green; */
    padding: 18px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #00000051;
    cursor: pointer;
  }

  .navbar-item:last-child {
    margin-top: 50px;
  }

  .navbar-text {
    display: flex;
    color: #ffffff;
    font-size: 18px;
    margin-left: 20px;
  }

  .navbar-close {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    cursor: pointer;
  }

  .navbar-close:hover {
    opacity: 1;
  }

  .navbar-close:before,
  .navbar-close:after {
    position: absolute;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #ffffff;
  }

  .navbar-close:before {
    transform: rotate(45deg);
  }

  .navbar-close:after {
    transform: rotate(-45deg);
  }
}

.street-card-container {
  width: 100vw;
  min-height: 100vh;
  background-color: #D5E0FF;
}

.street-card-c {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  height: 100vh;
  flex-direction: column;
  padding-top: 25%;
}

.street-card-background {
  width: 70%;
  aspect-ratio: 1/1.5;
  background-color: #ffffff;
  border: 1px solid darkblue;
  padding: 8px;
  position: relative;
}

/* Tablet */
@media only screen and (min-width:480px) and (max-width:768px) {
  .street-card-c {
    justify-content: center;
    padding-top: 0;
  }

  .street-card-background {
    width: 60%;
    width: 60%;
  }
}

/* Midsize */
@media only screen and (min-width:769px) and (max-width:1279px) {
  .street-card-background {
    width: 40%;
  }

  .street-card-c {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

/* Bigsize */
@media only screen and (min-width:1280px) {
  .street-card-background {
    width: 25%;
  }

  .street-card-c {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

.street-card-name-container {
  height: 25%;
  width: 100%;
  /* background-color: #EC1C22; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
}

.street-card-name {
  font-size: 30px;
  font-weight: 900;
  margin: 0;
}

.street-card-district {
  font-size: 18px;
  font-weight: 900;
  margin: 0;
  margin-top: 5px;
}

.navbar-icon {
  width: 100px;
}

.qr-scanner-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  width: 100%;
  padding-top: 80px;

}

.qr-scanner-text-container {
  width: 30%;
  background-color: #6B7AC9;
  color: #ffffff;
  border-radius: 20px;
  /* width: 80%; */
  padding: 5px;
}

/* style= {
    {
    height: 'calc(100vw * 80%)', width: '80%', marginTop: '50px', overflow: 'hidden',
  }
} */

.qr-scanner {
  margin-top: 50px;
  width: 30%;
}

.qr-scanner-viewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  overflow: hidden;
  /* box-sizing: border-box; */
  /* border: 50px solid rgba(0, 0, 0, .5); */
  z-index: 1;
}

.qr-scanner-viewer::after {

  content: "";
  position: absolute;
  top: 10%;
  left: 10%;
  border-radius: 20px;
  height: 80%;
  width: 80%;
  opacity: 20%;
  box-shadow: 0 0 0 500px #fff;
}

.qr-scanner-corner {
  position: absolute;
  /* border: 5px solid blue; */
  margin: 0;
  padding: 15px;
}

.qr-scanner-corner:nth-child(1) {
  border-top: 5px solid #fff;
  border-left: 5px solid #fff;
  top: 18%;
  left: 18%;
}

.qr-scanner-corner:nth-child(2) {
  border-top: 5px solid #fff;
  border-right: 5px solid #fff;
  top: 18%;
  right: 18%;
}

.qr-scanner-corner:nth-child(3) {
  border-bottom: 5px solid #fff;
  border-left: 5px solid #fff;
  bottom: 18%;
  left: 18%;
}

.qr-scanner-corner:nth-child(4) {
  border-bottom: 5px solid #fff;
  border-right: 5px solid #fff;
  bottom: 18%;
  right: 18%;
}

@media only screen and (max-width:479px) {
  .qr-scanner-text-container {
    width: 80%;
  }

  .qr-scanner {
    width: 80%;
  }

}

/* Tablet */
@media only screen and (min-width:480px) and (max-width:768px) {
  .qr-scanner-text-container {
    width: 50%;
  }

  .qr-scanner {
    width: 50%;
  }
}

.street-card-info-container {
  /* height: 100%; */
  margin: 10px;

}

.horizontal-line {
  width: 100%;
  background-color: darkblue;
  height: 1px;
}

.overflow-hidden {
  overflow: hidden;
}

.small-text {
  font-size: 5px;
  font-weight: 900;
}

/* CSS */
.button-46 {
  align-items: center;
  background-color: #EFF0F4;
  border: 1px solid #DFDFDF;
  border-radius: 16px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: flex;
  /* font-family: Inter, sans-serif; */
  font-size: 1em;
  justify-content: center;
  line-height: 18px;
  max-width: 50%;
  padding: 14px 0px;
  text-decoration: none;
  transition: all .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  margin-top: 50px;
  /* position: fixed; */
  /* bottom: 15%; */

}

.button-46:active,
.button-46:hover {
  outline: 0;
}

.button-46:hover {
  background-color: #DFDFDF;
  border-color: rgba(0, 0, 0, 0.19);
}

@media (min-width: 768px) {
  .button-46 {
    font-size: 20px;
    min-width: 200px;
    padding: 14px 16px;
  }
}

.admin-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1em;
  padding: 1em;
  /* padding-top: 100px; */
  /* grid-row-gap: 2em; */

}

.admin-button-container {
  /* margin: 40px; */
  width: 100%;
  height: 100%;
  background-color: #6B7AC9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  margin-top: 50px;
  cursor: pointer;

}

.admin-button-container.disabled {
  background-color: #b5bde4;
}

.admin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 16px;

  p {
    margin: 0;
    padding: 0;
  }

}

.admin-button-container.disabled:active {
  background-color: #b5bde4;
}

.admin-button-container:active {
  background-color: #363d65;
}

.board-icon-container {
  padding-top: 10px;
}

.board-icon-container.chance {
  margin-left: 8%;
  margin-right: 8%;
  padding-top: 8px;
}

.firstLine-right.chance {
  /* margin-left: 8%; */
  margin-right: 8%;
  padding-left: 8px;
}

.board-icon {
  width: 80%;
  /* padding-top: 8px; */
}