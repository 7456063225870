.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-home {
    width: 48px;
    height: 48px;
    position: relative;
    overflow: hidden;
    display: block;
  }
  
  .icon-home .home-chimney {
    width: 12%;
    height: 18%;
    position: absolute;
    left: 20%;
    top: 21%;
    background-color: #000;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
  }
  
  .icon-home .home-roof-container {
    width: 60%;
    height: 60%;
    position: absolute;
    left: 50%;
    margin-left: -30%;
    top: 20%;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); 
    overflow: hidden;
  }
  
  .icon-home .home-roof-left {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    left: -84%;
  }
  
  .icon-home .home-roof-right {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    top: -84%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); 
  }
  
  .icon-home .home-body {
    width: 60%;
    height: 70%;
    position: absolute;
    left: 50%;
    margin-left: -30%;
    bottom: 10%;
    background-color: #000;
  }
  
  .icon-home .home-roof-cut-left {
    width: 60%;
    height: 70%;
    position: absolute;
    left: -8%;
    bottom: 55%;
    /* background-color: #D5E0FF; */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); 
  }
  
  .icon-home .home-roof-cut-right {
    width: 60%;
    height: 70%;
    position: absolute;
    right: -8%;
    bottom: 55%;
    /* background-color: #D5E0FF; */
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); 
  }
  
  /* .icon-home .home-door {
    width: 22%;
    height: 38%;
    position: absolute;
    left: 50%;
    margin-left: -11%;
    bottom: 00%;
    background-color: #151517;
    border-radius: 15%;
  } */