/* BASE RULES */
.swipe-animation {

    div {
        text-align: center;
    }
    
    svg {
        display: inline-block;
        width: 100px;
        margin: 3% auto;
        padding: 0px 100px;
    }
}  
    /* SVG RULES */
    
    .hand, 
    .hand-double, 
    .hand-flick, 
    .hand-hold, 
    .hand-rock, 
    .hand-tap, 
    .hand-x, 
    .hand-y {
        fill: #fff;
        stroke: #000;
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .hand-x {
    
            /* opacity: .75; */
        
    }
    
    .arrow-down, 
    .arrow-head, 
    .arrow-left, 
    .arrow-right, 
    .arrow-up, 
    .arrow-up-1, 
    .arrow-up-2, 
    .arrow-up-3,   
    .hold-1,
    .hold-2, 
    .line-horizontal, 
    .line-rotate, 
    .line-vertical, 
    .notes, 
    .tap-1, 
    .tap-2 {
        fill: transparent;
        stroke: #000;
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    
    .arrow-up-3, 
    .swipe-horizontal, 
    .swipe-rotate, 
    .swipe-vertical {
        opacity: .75;
    }
    
    /* ANIMATION KEYFRAMES */
    
    @keyframes swipe-x {
        0% {
            
        }
        100% {
            transform: translateX(50px) rotateZ(10deg);
        }
    }

    
    /* SVG ANIMATION */
    
    .hand-x {
        animation: swipe-x .8s  linear alternate;
        animation-iteration-count: 3;
    }

    .hand, 
    .swipe-rotate {
        animation: spin 1.25s ease-in-out backwards;
        animation-iteration-count:infinite;
    }