table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  /* th {
    font-weight: 600;
  } */
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #fff;
  }

  .table-container {
    width: 80%;
    padding-bottom: 80px;
  }

  .page-container {
    background-color: #D5E0FF;
  }